import * as assetApi from 'api/app/asset'
import { TempDataTypes } from 'api/app/tempData'
import { ASSET_CHANGE_SITUATION_TYPES, EXCISE_TAX_INPUT_FORMAT } from 'common/constants'
import { checkRetroActiveFixedAsset, getAssetPayload, hasEventOccurred, parseDataForForm } from 'common/helpers'
import { FORM_MODES } from 'common/models'
import { Asset } from 'common/models/asset'
import { parseErrorResponse } from 'common/utils/response-helper'
import { BreadcrumbNavigation } from 'components/molecules'
import { AssetForm, AssetTemporaryDataModal } from 'components/organisms'
import { validateRequiredField } from 'components/organisms/assets/AssetForm/helper'
import { getGeneralValidationErrors } from 'components/organisms/assets/AssetForm/validations/asset-schema'
import { MainTemplate } from 'components/templates'
import useRetroactiveModal from 'components/templates/modal/RetroactiveAssetModal'
import i18n from 'i18n'
import useResource from 'lib/hooks/useResource'
import { get, isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { cleanupAssetItem, createAsset, fetchAsset } from 'store/asset/actions'
import {
  selectAssetItemData,
  selectAssetItemErrors,
  selectAssetItemLoading,
  selectEventHistories,
} from 'store/asset/selectors'
import { fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'
import { fetchExciseSettingsByDate } from 'store/settings/excise/actions'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { clearTempData, createTempData, fetchTempData } from 'store/tempData/actions'
import { selectTempDataData, selectTempDataSaveAt } from 'store/tempData/selectors'

const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={i18n.t('pages.assets.add')} />

const AssetCopyPage: React.FC = () => {
  const { biid } = useParams<any>()
  const dispatch = useDispatch()
  const confirmModal = useRetroactiveModal()

  const [validateAssetRes, validateAsset] = useResource(assetApi.create)
  const isValidateAssetLoading = validateAssetRes.isLoading

  const [showRequiredError, setShowRequiredError] = useState<boolean>(false)
  const [asset, setAsset] = useState()

  const errors = useSelector(selectAssetItemErrors, isEqual)
  const loading = useSelector(selectAssetItemLoading, isEqual)
  const assetData = useSelector(selectAssetItemData, isEqual)
  const tempAssetData = useSelector(selectTempDataData, isEqual)
  const tempAssetSavedAt = useSelector(selectTempDataSaveAt, isEqual)
  const terms = useSelector(selectTermsData, isEqual)
  const { data: exciseSetting } = useSelector(getExciseSettingsByDateList, isEqual)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const eventHistories = useSelector(selectEventHistories, isEqual)

  const [confirmVisible, setConfirmVisible] = useState<boolean>(false)
  const [hasConfirmedTempData, setHasConfirmedTempData] = useState<boolean>(false)
  const [initialData, setInitialData] = useState<Asset | null>(null)
  const [isAppliedTemporary, setIsAppliedTemporary] = useState<boolean>(false)
  const [validateErrors, setValidateErrors] = useState(null)

  const isTaxInputFormatExternalTax = exciseSetting?.tax_input_format === EXCISE_TAX_INPUT_FORMAT.EXTERNAL_TAX
  const acquiredAt = get(assetData, 'acquired_at')
  const transitionAt = get(assetData, 'transition_at')

  const removeImpairInfoLedger = (assetItem: any) => {
    assetItem?.fixed_asset_ledgers?.map((ledger: any) => {
      delete ledger?.accumulated_impaired_amount
      return ledger
    })
    return assetItem
  }

  const handleSubmit = (assetItemParam: any) => {
    const assetItem = removeImpairInfoLedger(assetItemParam)

    setAsset(assetItem)
    if (validateRequiredField(assetItem) || !!getGeneralValidationErrors().join(',')) {
      setShowRequiredError(true)
      return
    }

    const assetItemParams = getAssetPayload(assetItem)
    validateAsset({ ...assetItemParams, dry_run: true })
      .then(res => {
        if (res.error) {
          parseErrorResponse(
            {
              response: {
                status: res.status,
                data: { errors: res.error },
              },
            },
            setValidateErrors
          )
        }
        return checkRetroActiveFixedAsset({
          res,
          assetItem,
          currentMonthlyClosing,
          confirmModal,
        })
      })
      .then(isOK => {
        isOK === true && dispatch(createAsset(assetItem))
      })
  }

  const handleCancelConfirm = () => {
    dispatch(clearTempData())
    setConfirmVisible(false)
    setHasConfirmedTempData(true)
    setIsAppliedTemporary(false)
  }

  const handleOkConfirm = () => {
    tempAssetData && setInitialData({ ...tempAssetData })
    setConfirmVisible(false)
    setHasConfirmedTempData(true)
    setIsAppliedTemporary(true)
  }

  const handleSaveTemp = (dataType: string, data: any) => {
    hasConfirmedTempData && dispatch(createTempData(dataType, data))
  }

  useEffect(() => {
    const exciseSettingDate = transitionAt || acquiredAt

    exciseSettingDate && dispatch(fetchExciseSettingsByDate(exciseSettingDate))
  }, [acquiredAt, transitionAt]) // eslint-disable-line

  useEffect(() => {
    if (!assetData || !terms) return

    setInitialData({
      ...parseDataForForm(assetData),
      code: '',
      branch_code: '',
      min_acquired_at: terms[0]?.start_date,
      acquisition_cost_input: isTaxInputFormatExternalTax
        ? assetData.acquisition_cost_excluded_excise
        : assetData.acquisition_cost,
    })
  }, [assetData, terms, isTaxInputFormatExternalTax])

  useEffect(() => {
    dispatch(fetchTempData(TempDataTypes.CREATE_ASSET))
    !terms && dispatch(fetchTerms())

    return function cleanup() {
      dispatch(clearTempData())
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (tempAssetData) {
      setConfirmVisible(true)
      setHasConfirmedTempData(false)
    } else {
      setHasConfirmedTempData(true)
    }
  }, [tempAssetData])

  useEffect(() => {
    const hasDividingEvent = hasEventOccurred(eventHistories, ASSET_CHANGE_SITUATION_TYPES.DIVIDING)
    dispatch(fetchAsset(biid, hasDividingEvent))

    return function cleanup() {
      dispatch(cleanupAssetItem())
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    dispatch(fetchLedgerSettingsList({}))
  }, []) // eslint-disable-line

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      {assetData && (
        <AssetForm
          loading={loading || isValidateAssetLoading}
          errors={validateErrors || errors}
          onSubmit={handleSubmit}
          initialAssetData={initialData}
          formMode={FORM_MODES.COPY}
          tempAssetSavedAt={tempAssetSavedAt}
          onSaveTemp={handleSaveTemp}
          showRequiredError={showRequiredError}
          asset={asset}
          isTemporaryData={isAppliedTemporary}
        />
      )}
      {tempAssetData && (
        <AssetTemporaryDataModal
          isShow={confirmVisible}
          tempAssetData={tempAssetData}
          onCancel={handleCancelConfirm}
          onOk={handleOkConfirm}
        />
      )}
    </MainTemplate>
  )
}

export default AssetCopyPage
