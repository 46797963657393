import { number, object } from 'yup'

import {
  depreciationSchema,
  getLedgerGeneralValidationErrors,
  resetLedgerGeneralValidationErrors,
} from './deprecication-schema'

export const assetSchema = object().shape({
  book_value_at_change_reference: number(),
  fixed_asset_ledgers: depreciationSchema,
})

export function getGeneralValidationErrors(errors?: any) {
  return getLedgerGeneralValidationErrors(errors)
}

export function resetGeneralValidationErrors() {
  resetLedgerGeneralValidationErrors()
}
