import { Button, messageFn, messageSuccess, Spin } from 'aa_common/front-end/antd'
import { CSVCharsetRadios, DownloadLink, ErrorsList } from 'aa_common/front-end/components'
import { message, Upload } from 'antd'
import * as recalculateApi from 'api/app/recalculate'
import history from 'browserHistory'
import { CSV_CHARSET, FEATURES_ID, MAX_IMPORT_FILE_SIZE } from 'common/constants'
import { getRecalculateError, isSupportedCharset, parseImportErrors, validateFileSize } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { CsvCharset } from 'common/models'
import { FixedAssetEventsService } from 'common/open-api'
import { getAxiosSilent } from 'common/open-api/helper'
import { FeatureFlagNames, openFeatureSlice } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import useResource from 'lib/hooks/useResource'
import useUserPermission from 'lib/hooks/useUserPermission'
import get from 'lodash/get'
import {
  IMPORT_EXPORT_EVENT_TYPES,
  ImportExportEventTypeSelector,
} from 'pages/assets/AssetListPage/import-export-event-type-selector'
import { importExportEventTypeSelectorSlice, importExportStore } from 'pages/assets/AssetListPage/store'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showErrorsAppCall } from 'store/app/actions'
import { validateImportCsv } from 'store/assetImport/actions'

import { importBulkChangeAction } from './store'
import { Body, Header, Wrapper } from './styles'

const { t, useNsReady } = getNs([
  'components/molecules/auto-complete-select',
  'common',
  'components/organisms/assets/asset-import-form',
  'components/molecules/modals/import-modal',
  'pages/assets',
])

const { Dragger } = Upload

const AssetImportForm: React.FC = () => {
  useNsReady()
  const dispatch = useDispatch()
  const remoteFlags = openFeatureSlice.useSlice()
  const { importEventType, templateCharset, downloadTemplateType } = importExportEventTypeSelectorSlice.useSlice()
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [{ data: recalculateHistory }, getRecalculateHistory] = useResource(recalculateApi.getHistory)
  const [isUploading, setIsUploading] = useState(false)

  const beforeUpload = (file: File): boolean => {
    if (!validateFileSize(file, MAX_IMPORT_FILE_SIZE)) {
      message.error(t('msg_file_size_limit'), 2)
    } else {
      setUploadedFile(file)
    }

    return false
  }

  const permissionEventTypeMap = new Map([
    [IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER, useUserPermission(FEATURES_ID.FIXED_ASSETS)],
    [IMPORT_EXPORT_EVENT_TYPES.START_USAGE, useUserPermission(FEATURES_ID.START_USAGE)],
    [IMPORT_EXPORT_EVENT_TYPES.MOVEMENT, useUserPermission(FEATURES_ID.MOVEMENT)],
    [IMPORT_EXPORT_EVENT_TYPES.RETIREMENT, useUserPermission(FEATURES_ID.RETIRE)],
    [IMPORT_EXPORT_EVENT_TYPES.SALE, useUserPermission(FEATURES_ID.SALE)],
    [IMPORT_EXPORT_EVENT_TYPES.IMPAIRMENT, useUserPermission(FEATURES_ID.IMPAIRMENT)],
    [IMPORT_EXPORT_EVENT_TYPES.ASSET_TYPE_MODIFICATION, useUserPermission(FEATURES_ID.TYPE_MODIFICATION)],
  ])

  const isNotPermissionbyEvent = (importEventType: number | null): boolean => {
    if (importEventType !== null && permissionEventTypeMap.has(importEventType)) {
      const permissions = permissionEventTypeMap.get(importEventType)?.permissions
      return !!(permissions && !permissions.isCRUD && !permissions.isCRU)
    }
    return false
  }

  const [preImportErrs, setPreImportErrs] = useState<string | string[] | null>()
  const handleSubmit = async () => {
    setPreImportErrs(null)
    if (importEventType && isNotPermissionbyEvent(importEventType)) {
      const eventTypes = new Map([
        [IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER, t('fixed_asset_ledger')],
        [IMPORT_EXPORT_EVENT_TYPES.START_USAGE, t('start_usage')],
        [IMPORT_EXPORT_EVENT_TYPES.MOVEMENT, t('movement')],
        [IMPORT_EXPORT_EVENT_TYPES.RETIREMENT, t('retirement')],
        [IMPORT_EXPORT_EVENT_TYPES.SALE, t('sale')],
        [IMPORT_EXPORT_EVENT_TYPES.IMPAIRMENT, t('impairment')],
        [IMPORT_EXPORT_EVENT_TYPES.ASSET_TYPE_MODIFICATION, t('asset_type_modification')],
      ])
      setPreImportErrs(t('msg_not_permission', { event_name: eventTypes.get(importEventType) }))
      return
    }
    if (uploadedFile) {
      await hookFnc.handleSubmit(
        setPreImportErrs,
        uploadedFile,
        setIsUploading,
        importEventType,
        isAllowedBulkChangeForImport,
        dispatch
      )
    }
  }
  useEffect(() => {
    getRecalculateHistory()
    const recalculateError = getRecalculateError(recalculateHistory)
    recalculateError && dispatch(showErrorsAppCall(recalculateError))
  }, []) //eslint-disable-line

  useEffect(() => {
    importExportStore.setTemplateCharset(CSV_CHARSET.UTF8)
  }, [])

  const isAllowedBulkChangeForImport = remoteFlags[FeatureFlagNames.CollectionViewBulkChangeImport]

  const templateImportFiles = new Map([
    [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER}`, 'fixed_assets_onboarding_sjis.csv'],
    [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.START_USAGE}`, 'fixed_assets_startusage_sjis.csv'],
    [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.IMPAIRMENT}`, 'fixed_assets_impairment_sjis.csv'],
    [
      `${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.ASSET_TYPE_MODIFICATION}`,
      'fixed_assets_assettype_sjis.csv',
    ],
    [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.RETIREMENT}`, 'fixed_assets_retirement_sjis.csv'],
    [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.SALE}`, 'fixed_assets_sale_sjis.csv'],
    [`${CSV_CHARSET.SHIFT_JIS}-${IMPORT_EXPORT_EVENT_TYPES.MOVEMENT}`, 'fixed_assets_movement_sjis.csv'],
    [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER}`, 'fixed_assets_onboarding_utf8.csv'],
    [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.START_USAGE}`, 'fixed_assets_startusage_utf8.csv'],
    [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.IMPAIRMENT}`, 'fixed_assets_impairment_utf8.csv'],
    [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.ASSET_TYPE_MODIFICATION}`, 'fixed_assets_assettype_utf8.csv'],
    [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.RETIREMENT}`, 'fixed_assets_retirement_utf8.csv'],
    [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.SALE}`, 'fixed_assets_sale_utf8.csv'],
    [`${CSV_CHARSET.UTF8}-${IMPORT_EXPORT_EVENT_TYPES.MOVEMENT}`, 'fixed_assets_movement_utf8.csv'],
  ])
  const { isEnable: isEnableDmethodD10D11 } = useCheckFlag(FeatureFlagNames.ChangeDmethodD10D11)

  const downloadFile = templateImportFiles.get(`${templateCharset}-${downloadTemplateType}`)
  const baseLink = useMemo(() => {
    if (downloadTemplateType === IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER || !isAllowedBulkChangeForImport) {
      return isEnableDmethodD10D11 ? '/files/import_samples/change_dmethod_d10_d11/' : '/files/import_samples/'
    }
    return '/files/import_samples/'
  }, [downloadTemplateType, isEnableDmethodD10D11, isAllowedBulkChangeForImport])
  return (
    <Wrapper>
      {preImportErrs && <ErrorsList errors={preImportErrs} />}
      <Header>{t('header_1')}</Header>
      <Body>
        {!isAllowedBulkChangeForImport && (
          <>
            <div>
              <a target="_blank" href={`${baseLink}fixed_assets_onboarding_sjis.csv`} rel="noreferrer">
                <DownloadLink>{t('download_shiftjis')}</DownloadLink>
              </a>
            </div>
            <div style={{ marginTop: 5 }}>
              <a target="_blank" href={`${baseLink}fixed_assets_onboarding_utf8.csv`} rel="noreferrer">
                <DownloadLink>{t('download_utf8')}</DownloadLink>
              </a>
            </div>
          </>
        )}
        <div style={{ marginTop: !isAllowedBulkChangeForImport ? 12 : 0 }} className="bold">
          {t('precaution')}
        </div>
        <div style={{ whiteSpace: 'pre-wrap', marginTop: 5 }}>{t('precaution_content')}</div>
        {isAllowedBulkChangeForImport && (
          <>
            <ImportExportEventTypeSelector
              name="downloadTemplateType"
              onChange={(downloadTemplateType: number) =>
                importExportStore.setDownloadTemplateType(downloadTemplateType)
              }
              tooltipOverlayInnerStyle={{ width: 355 }}
            />
            <div style={{ marginBottom: -5, fontWeight: 500 }}>{t('character_code')}</div>
            <CSVCharsetRadios
              isShortCharsetLabel
              handleChange={(charset: string) => importExportStore.setTemplateCharset(charset)}
              tooltipOverlayInnerStyle={{ width: 270 }}
            />
            <div className="action">
              <a target="_blank" href={`${baseLink}${downloadFile}`} rel="noreferrer">
                <Button>{t('actions.download')}</Button>
              </a>
            </div>
          </>
        )}
      </Body>
      <Header>{t('header_2')}</Header>
      <Body>
        <div className="bold">{t('precaution_import')}</div>
        <div>{t('precaution_import_desc')}</div>
        {isAllowedBulkChangeForImport && (
          <ImportExportEventTypeSelector
            name="importEventType"
            tooltip={t('import_tooltip_content')}
            onChange={(importEventType: number) => importExportStore.setImportEventType(importEventType)}
          />
        )}
        <div>
          <Spin indicatorSize={40} loading={isUploading}>
            <Upload accept=".csv" fileList={[]} beforeUpload={beforeUpload} multiple={false}>
              <Button color="grey">{t('select_attachment')}</Button>
              <label style={{ marginLeft: 5 }}>{uploadedFile ? uploadedFile.name : t('not_selected')}</label>
            </Upload>
            <Dragger multiple={false} style={{ marginTop: 10, height: 122 }} beforeUpload={beforeUpload} accept=".csv">
              <p className="ant-upload-text">{t('drag_and_drop_here')}</p>
            </Dragger>
            <div className="action">
              <Button onClick={handleSubmit} disabled={!uploadedFile}>
                {t('actions.import')}
              </Button>
            </div>
          </Spin>
        </div>
      </Body>
    </Wrapper>
  )
}

export default AssetImportForm

export const hookFnc = {
  handleSubmitError: (error: any, setPreImportErrs: any, importEventType: any) => {
    const errorResponseData = error?.response?.data
    if (errorResponseData?.data?.events?.length) {
      const unexpectedError = errorResponseData?.data?.unexpected_preview_error?.detail
      unexpectedError && messageFn().error(unexpectedError)

      importBulkChangeAction.addState(errorResponseData.data, importEventType)
      importBulkChangeAction.setErrors(errorResponseData.errors)
      history.push('/assets/import/results')
    } else {
      setPreImportErrs(parseImportErrors(get(error, 'response.data'))?.details)
    }
  },
  checkIsImportFixedAssetLedger: (importEventType: any, isAllowedBulkChangeForImport: any) => {
    return (
      (importEventType !== null && importEventType === IMPORT_EXPORT_EVENT_TYPES.FIXED_ASSET_LEDGER) ||
      !isAllowedBulkChangeForImport
    )
  },
  handleSubmit: async (
    setPreImportErrs: any,
    uploadedFile: File | null,
    setIsUploading: any,
    importEventType: any,
    isAllowedBulkChangeForImport: any,
    dispatch: any
  ) => {
    const { handleSubmitError } = hookFnc
    setPreImportErrs(null)

    isSupportedCharset(uploadedFile!).then(async ({ charset, isSupported, notSupportedMsg }) => {
      // @ts-ignore
      notSupportedMsg && setPreImportErrs(notSupportedMsg)
      if (isSupported) {
        setIsUploading(true)
        try {
          if (hookFnc.checkIsImportFixedAssetLedger(importEventType, isAllowedBulkChangeForImport)) {
            dispatch(validateImportCsv(charset as CsvCharset, uploadedFile!))
            setIsUploading(false)
          } else {
            const res = await FixedAssetEventsService.eventsValidateCsvCreate(
              {
                file: uploadedFile!,
                charset: charset as CsvCharset,
                event_type: importEventType as number,
              },
              getAxiosSilent()
            )
            messageSuccess(t('messages.imported_success'))
            importBulkChangeAction.addState(res.data, importEventType)
            history.push('/assets/import/results')
          }
        } catch (error: any) {
          handleSubmitError(error, setPreImportErrs, importEventType)
        } finally {
          setIsUploading(false)
        }
      }
    })
  },
}
