import { ifElse, isEmptyValue } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { yen } from 'common/helpers'
import { AssetLedger } from 'common/models'
import { get } from 'lodash'

export function getOriginalData(
  t: Function,
  ledger: AssetLedger,
  depreciationRate: number | null,
  renderDepreciationRate: any
) {
  const {
    residual_amount,
    service_life,
    memorandum_value,
    usage_period_months,
    acquisition_cost_for_straight_line,
    service_life_for_depreciation_calc,
  } = ledger
  const defaultForNullValue = 'ー'
  return {
    depreciationTypeData: {
      title: t('depreciation_type'),
      value: get(ledger, 'depreciation_method.name_jp', defaultForNullValue),
    },
    residualValueData: {
      title: t('residual_value'),
      value: ifElse(residual_amount, yen(residual_amount!), defaultForNullValue),
    },
    serviceLifeData: {
      title: t('service_life'),
      value: `${t('service_life_amount', { amount: service_life })}`,
    },
    serviceLifeData40: {
      title: t('service_life'),
      value: t('service_life_amount', {
        amount: !isEmptyValue(usage_period_months) ? usage_period_months! / 12 : defaultForNullValue,
      }),
    },
    usagePeriodMonths: {
      title: t('usage_period_months'),
      value: ifElse(usage_period_months, `${usage_period_months}${t('units.month_amount')}`, defaultForNullValue),
    },
    maximumDepreciationAmountData: { title: t('maximum_depreciation_amount'), value: defaultForNullValue },
    depreciationRateData: {
      title: t('depreciation_rate'),
      value: `${ifElse(!isEmptyValue(depreciationRate), depreciationRate, defaultForNullValue)}`,
    },
    memorandomData: {
      title: t('memorandum'),
      value: ifElse(memorandum_value, yen(memorandum_value!), defaultForNullValue),
    },
    guarateeRateData: {
      title: t('guarantee_rate'),
      value: get(renderDepreciationRate, 'guarantee_rate', defaultForNullValue),
    },
    revisedDepreciationRateData: {
      title: t('revised_depreciation_rate'),
      value: get(renderDepreciationRate, 'revised_depreciation_rate', 'ー'),
    },
    bookValueAtChange: {
      title: t('acquisition_cost_for_straight_line'),
      value: ifElse(
        !isEmptyValue(acquisition_cost_for_straight_line),
        yen(acquisition_cost_for_straight_line!),
        defaultForNullValue
      ),
    },
    serviceLifeAfterChange: {
      title: t('service_life'),
      value: ifElse(
        !isEmptyValue(service_life_for_depreciation_calc),
        `${t('service_life_amount', { amount: service_life_for_depreciation_calc })}`,
        defaultForNullValue
      ),
    },
    emptyColumn: { title: '', value: '' },
  }
}

export function isChangeDepreciationMethod(ledger: AssetLedger, acquisitionCost: number | null) {
  return (
    ledger.acquisition_cost_for_straight_line !== null &&
    ledger.service_life_for_depreciation_calc !== null &&
    (ledger.acquisition_cost_for_straight_line !== acquisitionCost ||
      ledger.service_life_for_depreciation_calc !== ledger.service_life)
  )
}

export function getProcessData(
  t: Function,
  ledger: AssetLedger,
  depreciationRate: number | null,
  renderDepreciationRate: any,
  acquisitionCost: number | null,
  isOpenFeatureFlagChangeDmethod: boolean | undefined
) {
  const {
    depreciationTypeData,
    residualValueData,
    serviceLifeData,
    serviceLifeData40,
    usagePeriodMonths,
    maximumDepreciationAmountData,
    depreciationRateData,
    memorandomData,
    guarateeRateData,
    revisedDepreciationRateData,
    bookValueAtChange,
    serviceLifeAfterChange,
    emptyColumn,
  } = getOriginalData(t, ledger, depreciationRate, renderDepreciationRate)
  return {
    dataDmethod10_11:
      isOpenFeatureFlagChangeDmethod && isChangeDepreciationMethod(ledger, acquisitionCost)
        ? [
            [depreciationTypeData, residualValueData],
            [serviceLifeAfterChange, maximumDepreciationAmountData],
            [depreciationRateData, memorandomData],
            [bookValueAtChange],
          ]
        : [
            [depreciationTypeData, residualValueData],
            [serviceLifeData, maximumDepreciationAmountData],
            [depreciationRateData, memorandomData],
          ],
    dataDmethod21_22: [
      [depreciationTypeData, residualValueData],
      [serviceLifeData, maximumDepreciationAmountData],
      [depreciationRateData, memorandomData],
      [guarateeRateData],
      [revisedDepreciationRateData],
    ],
    dataDmethod20_80: [
      [depreciationTypeData, residualValueData],
      [serviceLifeData, maximumDepreciationAmountData],
      [depreciationRateData, memorandomData],
    ],
    dataDmethod30: [
      [depreciationTypeData, emptyColumn],
      [serviceLifeData, emptyColumn],
      [depreciationRateData, memorandomData],
    ],
    dataDmethod40: [
      [depreciationTypeData, residualValueData],
      [serviceLifeData40, emptyColumn],
      [depreciationRateData, memorandomData],
    ],
    dataDmethod50_60: [
      [depreciationTypeData, residualValueData],
      [usagePeriodMonths, emptyColumn],
      [depreciationRateData, memorandomData],
    ],
    dataDmethod90_99: [[depreciationTypeData]],
  }
}

export function getDepreciationData(
  t: Function,
  ledger: AssetLedger,
  depreciationRate: number | null,
  renderDepreciationRate: any,
  depreciationMethodCode: number,
  acquisitionCost: number | null,
  isOpenFeatureFlagChangeDmethod: boolean | undefined
) {
  const {
    dataDmethod10_11,
    dataDmethod21_22,
    dataDmethod20_80,
    dataDmethod30,
    dataDmethod40,
    dataDmethod50_60,
    dataDmethod90_99,
  } = getProcessData(
    t,
    ledger,
    depreciationRate,
    renderDepreciationRate,
    acquisitionCost,
    isOpenFeatureFlagChangeDmethod
  )

  const depreciationData = new Map([
    // Data for Dmethod 10,11,20,80
    [DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE, dataDmethod10_11],
    [DEPRECIATION_METHOD_CODE.NEW_STRAIGHT_LINE, dataDmethod10_11],
    // Data for Dmethod 20,80
    [DEPRECIATION_METHOD_CODE.OLD_DECLINING_BALANCE, dataDmethod20_80],
    [DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET, dataDmethod20_80],
    // Data for Dmethod 21,22
    [DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_250, dataDmethod21_22],
    [DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_200, dataDmethod21_22],
    // Data for Dmethod 30
    [DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD, dataDmethod30],
    // Data for Dmethod 40
    [DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT, dataDmethod40],
    // Data for Dmethod 50,60
    [DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD, dataDmethod50_60],
    [DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE, dataDmethod50_60],
    // Data for Dmethod 90,99
    [DEPRECIATION_METHOD_CODE.NON_DEPRECIATION, dataDmethod90_99],
    [DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE, dataDmethod90_99],
  ])
  return depreciationData.get(depreciationMethodCode) || null
}
