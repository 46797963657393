import { Button } from 'aa_common/front-end/antd'
import { CONFIRM_TYPES } from 'aa_common/front-end/components'
import { FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { getConstant } from 'components/organisms/accounting/GeneralJournalSettingList/const'
import useModal from 'hooks/useModalHook'
import parser from 'html-react-parser'
import useJournalStatus from 'lib/hooks/useJournalStatus'
import useUserPermission from 'lib/hooks/useUserPermission'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { sendJournal } from 'store/accounting/journal/actions'

import { confirmContent } from './styles'

type Props = {
  isHasJournal: boolean
}

const t = loadNs([
  'common',
  'components/molecules/modals/confirm-send-journal-modal',
  'components/molecules/button-send-journal',
])
const JournalLinkageSender: React.FC<Props> = ({ isHasJournal }) => {
  const dispatch = useDispatch()
  const modal = useModal()
  const {
    permissions: { isExecute: isSendJournalLinkage },
  } = useUserPermission(FEATURES_ID.JOURNAL_LINKAGE)
  const { ACCOUNTING_PLUS_STATUS_VALUES } = getConstant()

  const { isSendJournalPermission, data } = useJournalStatus()

  const isSendJournal = isSendJournalPermission && isSendJournalLinkage

  const [isJournalHasBeenSent, setJournalSentState] = useState(false)

  const sendNotPermissionTooltip = () => {
    if (!isSendJournalLinkage) {
      return t('actions.no_permission')
    }
    if (!isSendJournalPermission) {
      return t('send_journal_disable_tooltip')
    }
    return ''
  }

  const handleSendJournal = () => {
    if (isSendJournal && isHasJournal) {
      // Handle get status, isSubmit status
      const isJournalApproved = data?.journal_linkage_status === ACCOUNTING_PLUS_STATUS_VALUES?.approve?.id
      const isSubmitted = !!data?.last_submit_in_current_month || isJournalHasBeenSent
      const approveKey = isJournalApproved ? 'approve' : 'unapproved'
      const submitKey = isSubmitted ? 'have_sent' : 'first_send'
      modal
        .confirm({
          title: t('title'),
          message: <p className={confirmContent}>{parser(t(`${approveKey}_${submitKey}`))}</p>,
          type: CONFIRM_TYPES.CONFIRM,
          okText: t('send_button'),
          cancelText: t('actions.cancel'),
          closable: true,
        })
        .then(isOKClick => {
          // Handle call API transfer journal
          if (isOKClick) {
            setJournalSentState(true)
            dispatch(sendJournal())
          }
        })
    }
  }

  return (
    <Button
      disabled={!isSendJournal || !isHasJournal}
      onClick={handleSendJournal}
      tooltipSettings={{ placement: 'bottom' }}
      showTooltipMessage={sendNotPermissionTooltip()}
    >
      {t('send_journal_to_account_plus')}
    </Button>
  )
}

export default JournalLinkageSender
