import { Tag } from 'common/models'

import { DeleteTagRequest } from './action.model'
import {
  CLEANUP_TAG,
  CREATE_TAG_REQUEST,
  DELETE_TAG_REQUEST,
  FETCH_TAG_REQUEST,
  FETCH_TAGS_FAILURE,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_REQUEST_AUTH,
  FETCH_TAGS_SUCCESS,
  UPDATE_TAG_ORDER_REQUEST,
  UPDATE_TAG_REQUEST,
} from './constant'
import { UpdateTagOrderPayload } from './model'

export const fetchTagList = (filters: any) => ({
  type: FETCH_TAGS_REQUEST,
  filters,
})

export const fetchTagListAuth = (filters: any) => ({
  type: FETCH_TAGS_REQUEST_AUTH,
  filters,
})

export const fetchTagListSuccess = (payload: any) => ({
  type: FETCH_TAGS_SUCCESS,
  payload,
})

export const fetchTagListFailure = (payload: any) => ({
  type: FETCH_TAGS_FAILURE,
  payload,
})

export const createTag = (payload: any): any => {
  return {
    type: CREATE_TAG_REQUEST,
    payload,
  }
}

export const deleteTag = (deletedTag: Tag): DeleteTagRequest => {
  return {
    type: DELETE_TAG_REQUEST,
    payload: deletedTag,
  }
}

export const fetchTag = (id: number): any => {
  return {
    type: FETCH_TAG_REQUEST,
    id,
  }
}

export const updateTag = (id: number, payload: any): any => {
  return {
    type: UPDATE_TAG_REQUEST,
    id,
    payload,
  }
}

export const cleanupTag = (): any => {
  return {
    type: CLEANUP_TAG,
  }
}

export const updateTagOrder = (payload: UpdateTagOrderPayload) => {
  return {
    type: UPDATE_TAG_ORDER_REQUEST,
    payload,
  }
}
