import { Drawer } from 'antd'
import styled from '@emotion/styled'

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: auto;
    right: 0px;
  }

  .ant-drawer-body {
    padding: 15px;
    margin-bottom: 26px;
  }
`
