import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import UnitSubmitForm from 'components/organisms/settings/UnitSubmitForm'
import { MainTemplate } from 'components/templates'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { cleanupUnit, createUnitRequest } from 'store/settings/unit/actions'

const t = loadNs(['pages/settings/units'])

type Props = {
  errors: Array<any>
  loading: boolean
  cleanupUnit: () => void
  createUnitRequest: (data: any) => void
}

const UnitNew = ({ errors, loading, createUnitRequest, cleanupUnit }: Props) => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('add')} />

  useEffect(() => {
    return cleanupUnit
  }, [cleanupUnit])

  const handleSubmit = (values: Record<string, any>) => {
    createUnitRequest(values)
  }

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <UnitSubmitForm loading={loading} errors={errors} onSubmit={handleSubmit} />
    </MainTemplate>
  )
}

const mapStateToProps = (state: Readonly<any>) => ({
  errors: state.getIn(['settings', 'unit', 'item', 'errors']),
  loading: state.getIn(['settings', 'unit', 'item', 'loading']),
})

const mapDispatchToProps = {
  cleanupUnit,
  createUnitRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitNew)
