import { parseError, parseResponse } from 'common/helpers'
import { UnitSubmitPayload, UpdateUnitOrderPayload } from 'store/settings/unit/model'

import base from './base'

export const list = () => {
  return base
    .get('/v1/units')
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const listAuth = () => {
  return base
    .get('/v1/units/get')
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const create = (data: UnitSubmitPayload) => {
  return base.post('/v1/units', data)
}

export const get = (id: number) => {
  return base.get(`/v1/units/${id}`)
}

export const update = (id: number, data: UnitSubmitPayload) => {
  return base.put(`/v1/units/${id}`, data)
}

export const destroy = (id: number, dryRun: boolean) => {
  return base.delete(`/v1/units/${id}`, { params: { dry_run: dryRun } })
}

export const change_position = (data: UpdateUnitOrderPayload) => {
  return base.patch('/v1/units/change_position', data)
}
