import { Button, Spin } from 'aa_common/front-end/antd'
import { CONFIRM_TYPES } from 'aa_common/front-end/components'
import { FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { AssetImportForm } from 'components/organisms'
import useModal from 'hooks/useModalHook'
import useUserPermission from 'lib/hooks/useUserPermission'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { initializeFixedAssets } from 'store/assetImport/actions'

import { Body, Header, Wrapper } from './styles'

const t = loadNs([
  'common',
  'components/organisms/assets/asset-import-form',
  'components/molecules/modals/import-modal',
])

const ImportSettingForm: React.FC = () => {
  const modal = useModal()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>()

  const handleInitializeFixedAssets = () => {
    modal
      .confirm({
        type: CONFIRM_TYPES.DELETE,
        title: t('modal_confirm_title'),
        message: (
          <>
            {t('modal_confirm_message_1')}
            <br />
            {t('modal_confirm_message_2')}
          </>
        ),
        closable: true,
        cancelText: t('modal_confirm_cancel'),
        okText: t('modal_confirm_delete'),
      })
      .then(isOK => {
        if (isOK) {
          setIsLoading(true)
          return dispatch(initializeFixedAssets())
        }
      })
      .finally(() => setIsLoading(false))
  }

  const { permissions } = useUserPermission(FEATURES_ID.RESET_ALL_DATA)
  return (
    <Spin loading={isLoading}>
      <Wrapper>
        <AssetImportForm />
        <Header>{t('header_3')}</Header>
        <Body>
          <Button
            color="grey"
            style={permissions.isExecute ? { color: 'red' } : undefined}
            disabled={!permissions.isExecute}
            showTooltipMessage={!permissions.isExecute ? t('actions.no_permission') : undefined}
            onClick={() => permissions.isExecute && handleInitializeFixedAssets()}
          >
            {t('reset_ledger')}
          </Button>
        </Body>
      </Wrapper>
    </Spin>
  )
}

export default ImportSettingForm
