import { Spin } from 'aa_common/front-end/antd'
import { FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { Tag } from 'common/models'
import { BreadcrumbNavigation, ListPageActions } from 'components/molecules'
import TagList from 'components/organisms/settings/TagList'
import { ListPageTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { cleanupTag, deleteTag, fetchTagListAuth, updateTagOrder } from 'store/settings/tag/actions'
import { UpdateTagOrderPayload } from 'store/settings/tag/model'
import { selectorTagList } from 'store/settings/tag/selectors'

const t = loadNs(['pages/settings/tags'])

const TagListPage = () => {
  const breadcrumb = <BreadcrumbNavigation sectionName={t('list')} />
  const dispatch = useDispatch()
  const tagListSelector = useSelector(selectorTagList, isEqual)

  useEffect(() => {
    dispatch(fetchTagListAuth({}))
    return function cleanup() {
      dispatch(cleanupTag())
    }
  }, []) // eslint-disable-line

  const handleEdit = useCallback(
    (item: Tag) => {
      dispatch(changeLocation(`/master/tags/${item.id}/edit`))
    },
    [dispatch]
  )

  const handleConfirmDelete = useCallback(
    (item: Tag) => {
      item && dispatch(deleteTag(item))
    },
    [dispatch]
  )

  const handleAddNew = () => {
    dispatch(changeLocation('/master/tags/new'))
  }

  const handleRowFinishDragging = useCallback(
    ({ beforeItem, afterItem, currentItem }) => {
      const payload: UpdateTagOrderPayload = {
        id_before: beforeItem && beforeItem.id,
        id_after: afterItem && afterItem.id,
        target_id: currentItem.id,
      }

      dispatch(updateTagOrder(payload))
    },
    [dispatch]
  )

  const memoizedTags = useMemo(() => tagListSelector.data, [tagListSelector.data])

  return (
    <ListPageTemplate breadcrumb={breadcrumb} maxWidth={600}>
      <Spin loading={tagListSelector.loading}>
        <ListPageActions
          featureId={FEATURES_ID.MASTER_TAGS}
          add={{ onClick: handleAddNew, disabled: tagListSelector.loading }}
        />
        <TagList
          tags={memoizedTags}
          onDelete={handleConfirmDelete}
          onEdit={handleEdit}
          onRowFinishDragging={handleRowFinishDragging}
        />
      </Spin>
    </ListPageTemplate>
  )
}

export default TagListPage
