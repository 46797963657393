import { Spin } from 'aa_common/front-end/antd'
import { BasicTable, CONFIRM_TYPES } from 'aa_common/front-end/components'
import { FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { ColumnState, IColumnSortEvent } from 'common/models'
import { NoDataImage } from 'components/atoms'
import { ListPageActions } from 'components/molecules'
import useModal from 'hooks/useModalHook'
import useUserPermission from 'lib/hooks/useUserPermission'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'

import { ASSET_CATEGORY_LIST_COLUMNS_WIDTH, convertDataToDisplay, getColumns, getDefaultColumnsState } from './helper'
import { ActionWrapper, EmptyImage, Wrapper } from './styles'

const t = loadNs(['common', 'components/organisms/settings/asset-category-list'])

type Props = {
  assetCategories: any
  loading?: boolean
  onCopy?: (item: any) => void
  onEdit?: (item: any) => void
  onDelete?: (item: any) => void
  onTableSort?: (event: any) => void
  onAddNew: () => void
  onImportClick: () => void
  onExportClick: () => void
}

const AssetCategoryList = (props: Props) => {
  const {
    assetCategories = [],
    loading = false,
    onCopy,
    onEdit,
    onDelete,
    onAddNew,
    onImportClick,
    onExportClick,
    onTableSort,
  } = props
  const modal = useModal()
  const { permissions: assetCategoryPermissions } = useUserPermission(FEATURES_ID.MASTER_ASSET_CATEGORIES)
  const masterData = useSelector(selectMasterData, isEqual)
  const [assetCategoryColumnsState, setColumnsState] = useState<ColumnState[] | undefined>(getDefaultColumnsState())

  const handleTableSort = useCallback<IColumnSortEvent>(
    sortEvent => {
      setColumnsState(sortEvent.tableColumns)
      onTableSort && onTableSort(sortEvent)
    },
    [onTableSort]
  )

  const handleDelete = useCallback(
    (deleteItem: any) => {
      modal
        .confirm({
          title: '',
          type: CONFIRM_TYPES.DELETE,
          message: t('messages.confirm_delete', { name: get(deleteItem, 'name') }),
        })
        .then(isOKClick => {
          if (isOKClick) {
            onDelete && onDelete(deleteItem)
          }
        })
    },
    [onDelete]
  ) //eslint-disable-line

  const memoizedAssetType = useMemo(() => {
    return masterData?.asset_type?.reduce((acc: any, item: any) => ({ ...acc, [item.code]: item.name_jp }), {}) || {}
  }, [JSON.stringify(masterData?.asset_type)])

  const memoizedAssetCategories = useMemo(() => {
    return convertDataToDisplay(assetCategories, getColumns(), memoizedAssetType)
  }, [assetCategories, memoizedAssetType])

  const disableActions = useMemo(
    () => ({
      copy: () => !assetCategoryPermissions.isCreate,
      edit: () => !assetCategoryPermissions.isEdit,
      delete: () => !assetCategoryPermissions.isDelete,
    }),
    [assetCategoryPermissions]
  )

  const showActionsTooltip = useMemo(
    () => ({
      copy: () => {
        return !assetCategoryPermissions.isCreate ? t('actions.no_permission') : undefined
      },
      edit: () => {
        return !assetCategoryPermissions.isEdit ? t('actions.no_permission') : undefined
      },
      delete: () => {
        return !assetCategoryPermissions.isDelete ? t('actions.no_permission') : undefined
      },
    }),
    [assetCategoryPermissions]
  )

  return (
    <Wrapper>
      <ActionWrapper>
        <ListPageActions
          featureId={FEATURES_ID.MASTER_ASSET_CATEGORIES}
          add={{ onClick: onAddNew, disabled: loading }}
          onImport={{ onClick: onImportClick, disabled: loading }}
          onExport={{ onClick: onExportClick, disabled: loading || assetCategories.length === 0 }}
        />
      </ActionWrapper>
      <Spin loading={loading}>
        {!assetCategories.length ? (
          <EmptyImage>
            <NoDataImage text={t('msg_no_data')} />
          </EmptyImage>
        ) : (
          <BasicTable
            columns={getColumns()}
            columnsState={assetCategoryColumnsState}
            columnsWidth={ASSET_CATEGORY_LIST_COLUMNS_WIDTH}
            data={memoizedAssetCategories}
            onCopy={onCopy}
            onEdit={onEdit}
            onDelete={handleDelete}
            onHeaderCellSortClick={handleTableSort}
            showActionsTooltip={showActionsTooltip}
            disableActions={disableActions}
          />
        )}
      </Spin>
    </Wrapper>
  )
}

export default AssetCategoryList
