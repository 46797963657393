import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CONFIRM_TYPES, LinkLabel } from 'aa_common/front-end/components'
import { URL_REOPEN_MONTHLY_GUIDE } from 'common/constants'
import useModal from 'hooks/useModalHook'
import i18n from 'i18n'
import { useCallback, useMemo } from 'react'

interface Props {
  onOKClick?: () => void
  onCancel?: () => void
}

const useRetroactiveModal = () => {
  const confirmModal = useModal()

  const open = useCallback(({ onOKClick, onCancel }: Props) => {
    return confirmModal
      .confirm({
        title: i18n.t('components.RetrospectiveAssetModal.title'),
        message: (
          <span>
            {`${i18n.t('components.RetrospectiveAssetModal.message')} `}
            <LinkLabel target="_blank" href={URL_REOPEN_MONTHLY_GUIDE}>
              <span>{i18n.t('components.RetrospectiveAssetModal.click_for_detail')}</span>
              <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faExternalLinkAlt} />
            </LinkLabel>
          </span>
        ),
        type: CONFIRM_TYPES.CONFIRM,
        closable: true,
        okText: i18n.t('common.actions.register'),
      })
      .then(isOK => {
        isOK ? onOKClick?.() : onCancel?.()
        return isOK
      })
  }, []) // eslint-disable-line

  return useMemo(() => ({ open }), [open])
}

export default useRetroactiveModal
