import { CommonErrorMsg } from 'aa_common/front-end/antd'
import classNames from 'classnames'
import { getIn, useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

import { Wrapper } from './styles'

type Props = {
  isShowError?: boolean
  name: string
  placeholder?: string
  style?: React.CSSProperties
  disabled?: boolean
  minValue?: number
  maxValue?: number
  currencyPrefix?: string
  afterChanged?: (value?: number) => void
  className?: string
  defaultValue?: number
  onChange?: (value?: number) => void
}

function getNumberInRange(value: number, minValue?: number, maxValue?: number): number {
  let newValue = value
  if (minValue !== undefined && newValue < minValue) {
    newValue = minValue
  }
  if (maxValue !== undefined && newValue > maxValue) {
    newValue = maxValue
  }
  return newValue
}

const MoneyInput: React.FC<Props> = ({
  name = '',
  isShowError = true,
  currencyPrefix = '¥',
  disabled = false,
  placeholder = '¥0',
  style,
  minValue,
  maxValue,
  afterChanged,
  className,
  defaultValue = 0,
  onChange,
}) => {
  const { values, errors, setFieldValue } = useFormikContext()
  const [value, setValue] = useState(getIn(values, name))
  const [previousValue, setPreviousValue] = useState(getIn(values, name))
  const fieldValue = getIn(values, name)
  const error = get(errors, name)

  useEffect(() => {
    if (!Number.isNaN(parseInt(String(fieldValue), 10))) {
      let newValue = parseInt(String(fieldValue), 10)
      newValue = getNumberInRange(newValue, minValue, maxValue)

      if (previousValue !== newValue) {
        setPreviousValue(newValue)
        setFieldValue(name, newValue)
        afterChanged?.(newValue)
      }
    }
  }, [minValue, maxValue])

  const handleChange = (event: NumberFormatValues) => {
    const newValue = event.floatValue || 0
    let newValueFormat = parseInt(`${newValue}`.replace(/\./g, ','), 10)
    newValueFormat = getNumberInRange(newValueFormat, minValue, maxValue)

    setFieldValue(name, newValueFormat)
    setValue(newValueFormat)
    onChange?.(newValueFormat)
  }

  const handleBlur = () => {
    if (previousValue !== value) {
      setPreviousValue(value)
      setFieldValue(name, value)
      afterChanged?.(value)
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setFieldValue(name, value)
    }
    if (event.key === '.') {
      event.preventDefault()
    }
  }

  const handleAfterFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => event.target.select())
  }

  useEffect(() => {
    setValue(fieldValue)
  }, [fieldValue]) // eslint-disable-line

  return (
    <Wrapper>
      <NumberFormat
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onValueChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleAfterFocus}
        onKeyPress={handleKeyPress}
        allowNegative={false}
        allowLeadingZeros={false}
        allowEmptyFormatting={false}
        thousandSeparator
        prefix={currencyPrefix}
        defaultValue={defaultValue}
        style={style}
        className={classNames(className, { error, disabled })}
      />
      {error && isShowError && <CommonErrorMsg>{error}</CommonErrorMsg>}
    </Wrapper>
  )
}

export default MoneyInput
