import { Spin } from 'aa_common/front-end/antd'
import { CONFIRM_TYPES } from 'aa_common/front-end/components'
import { ModalRef } from 'aa_common/front-end/hooks/useModalState'
import { loadNs } from 'common/i18n-config'
import { AutoGenerateSetting } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import { AssetCodeAutoGenerationFormModal, AssetCodeAutoGenerationView, AssetCodeFormProp } from 'components/organisms'
import { MainTemplate } from 'components/templates'
import useModal from 'hooks/useModalHook'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'
import {
  createAutoGenerateSetting,
  deleteAutoGenerateSetting,
  fetchAutoGenerateSettingsAuth,
} from 'store/settings/assetCode/actions'
import { selectLoadingState } from 'store/settings/assetCode/selectors'

const t = loadNs([
  'common',
  'pages/settings/asset-code',
  'components/organisms/settings/asset-code-auto-generation-view',
])

interface CallbackParams {
  title: string
  setting: AutoGenerateSetting
  allowSelectTerm?: boolean
}

const AssetCodeAutoGenerationPage: React.FC = () => {
  const breadcrumb = <BreadcrumbNavigation enableBackButton={false} sectionName={t('automatic_numbering_setting')} />
  const dispatch = useDispatch()
  const modal = useModal()

  const terms = useSelector(selectTermsData, isEqual)
  const isLoading = useSelector(selectLoadingState)

  useEffect(() => {
    dispatch(fetchTerms())
    dispatch(fetchMonthlyClosingCurrent())
    dispatch(fetchAutoGenerateSettingsAuth())
  }, [dispatch])

  const modalRef = useRef<ModalRef<AssetCodeFormProp>>(null)
  const openModal = useCallback(
    (params: CallbackParams) => {
      const { setting: initialValues, allowSelectTerm = true } = params
      if (initialValues) {
        modalRef.current?.open(
          {
            ...params,
            initialValues,
            onValuesCb: values => dispatch(createAutoGenerateSetting(values)),
          },
          { allowSelectTerm }
        )
      }
    },
    [dispatch]
  )

  const handleClickDelete = useCallback(
    ({ from_term, id }: AutoGenerateSetting) => {
      const i18Params = { fromTermYear: from_term?.year }
      modal
        .confirm({
          title: t('confirm_delete_modal_title', i18Params),
          message: t('confirm_delete_modal_content', i18Params),
          type: CONFIRM_TYPES.DELETE,
        })
        .then(isConfirmed => {
          isConfirmed && dispatch(deleteAutoGenerateSetting(id))
        })
    },
    [modal, dispatch]
  )

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <Spin loading={isLoading}>
        <AssetCodeAutoGenerationView
          terms={terms}
          onClickDelete={handleClickDelete}
          onClickTopEdit={setting =>
            openModal({
              setting,
              title: t('actions.edit'),
            })
          }
          onClickEdit={setting =>
            openModal({
              setting,
              allowSelectTerm: false,
              title: t('actions.modify'),
            })
          }
          onClickAdd={setting =>
            openModal({
              setting,
              title: t('actions.add'),
            })
          }
        />
      </Spin>
      <AssetCodeAutoGenerationFormModal ref={modalRef} />
    </MainTemplate>
  )
}

export default AssetCodeAutoGenerationPage
