import { Spin } from 'aa_common/front-end/antd'
import { FEATURES_ID } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { Unit } from 'common/models'
import { BreadcrumbNavigation, ListPageActions } from 'components/molecules'
import UnitList, { OnRowDragged } from 'components/organisms/settings/UnitList'
import { ListPageTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import {
  changePositionRequest,
  cleanupUnit,
  deleteUnitRequest,
  fetchUnitsRequestAuth,
} from 'store/settings/unit/actions'
import { selectorUnitList } from 'store/settings/unit/selectors'

const t = loadNs(['pages/settings/units'])

const UnitListPage = () => {
  const breadcrumb = <BreadcrumbNavigation sectionName={t('list')} />
  const dispatch = useDispatch()
  const unitListSelector = useSelector(selectorUnitList, isEqual)

  useEffect(() => {
    dispatch(fetchUnitsRequestAuth())
    return () => {
      dispatch(cleanupUnit())
    }
  }, []) // eslint-disable-line

  const handleAddNew = () => {
    dispatch(changeLocation('/master/units/new'))
  }

  const handleEdit = useCallback(
    (item: Unit) => {
      dispatch(changeLocation(`/master/units/${item.id}/edit`))
    },
    [dispatch]
  )

  const handleConfirmDelete = useCallback(
    (item: Unit) => {
      const { id, name } = item
      dispatch(deleteUnitRequest({ id, name }))
    },
    [dispatch]
  )

  const handleRowFinishDragging = useCallback(
    ({ beforeItem, afterItem, currentItem }: OnRowDragged) => {
      dispatch(
        changePositionRequest({
          id_before: beforeItem?.id,
          id_after: afterItem?.id,
          target_id: currentItem.id,
        })
      )
    },
    [dispatch]
  )

  const units = useMemo(() => unitListSelector.data, [unitListSelector.data])

  return (
    <ListPageTemplate breadcrumb={breadcrumb} maxWidth={600}>
      <Spin loading={unitListSelector.loading}>
        <ListPageActions
          featureId={FEATURES_ID.MASTER_UNITS}
          add={{ onClick: handleAddNew, disabled: unitListSelector.loading }}
        />
        <UnitList
          units={units}
          onEdit={handleEdit}
          onDelete={handleConfirmDelete}
          onRowFinishDragging={handleRowFinishDragging}
        />
      </Spin>
    </ListPageTemplate>
  )
}

export default UnitListPage
