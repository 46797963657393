import { CONFIRM_TYPES, ConfirmModal } from 'aa_common/front-end/components'
import { Role } from 'aa_common/front-end/models'
import { FEATURES_ID } from 'common/constants'
import { handlePermissionByFeatureId } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation, ImportExportButton as Button } from 'components/molecules'
import { RoleList } from 'components/organisms'
import { ListPageTemplate } from 'components/templates'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { selectUserPermission } from 'store/session/selectors'
import { cleanupRoles, deleteRoleCall, fetchUserRoles } from 'store/settings/role/actions'
import { selectUserRoleList } from 'store/settings/role/selectors'

const t = loadNs(['common', 'pages/settings/roles'])

const RoleListPage = () => {
  const breadcrumb = <BreadcrumbNavigation sectionName={t('role')} />
  const [deletedItem, setDeletedItem] = useState<Role | null>(null)

  const dispatch = useDispatch()

  const roleListSelector = useSelector(selectUserRoleList, isEqual)
  const { data: userPermissions } = useSelector(selectUserPermission, isEqual)

  const { isCreate } = handlePermissionByFeatureId(FEATURES_ID.SETTING_ROLES, userPermissions)

  useEffect(() => {
    dispatch(fetchUserRoles())

    return () => {
      dispatch(cleanupRoles())
    }
  }, []) // eslint-disable-line

  const handleOnAddNew = () => dispatch(changeLocation('/setting/roles/new'))

  const handleEditItemClick = useCallback(
    (item: Role) => {
      dispatch(changeLocation(`/setting/roles/${item.biid}/edit`))
    },
    [dispatch]
  )

  const handleDeleteItemClick = useCallback((item: Role) => setDeletedItem(item), [])

  const handleOnDeleteModalCancel = () => setDeletedItem(null)

  const handleConfirmDelete = () => {
    if (deletedItem?.biid) {
      dispatch(deleteRoleCall(deletedItem.biid))
      setDeletedItem(null)
    }
  }

  const isDeleteModalShow = !!deletedItem

  return (
    <ListPageTemplate maxWidth={900} breadcrumb={breadcrumb}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
        <Button type="import" disabled={!isCreate} onClick={handleOnAddNew}>
          {t('actions.add')}
        </Button>
      </div>
      <RoleList
        loading={roleListSelector.loading}
        data={roleListSelector.data}
        onEditClick={handleEditItemClick}
        onDeleteClick={handleDeleteItemClick}
      />
      <ConfirmModal
        type={CONFIRM_TYPES.DELETE}
        visible={isDeleteModalShow}
        title={t('delete_role_modal_title')}
        message={t('delete_role_modal_message')}
        onCancel={handleOnDeleteModalCancel}
        onOK={handleConfirmDelete}
      />
    </ListPageTemplate>
  )
}

export default RoleListPage
