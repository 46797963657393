import { parseDataForForm } from 'common/helpers'
import { Asset } from 'common/models'
import { parseValidationError } from 'common/utils/response-helper'
import { RequestState } from 'lib/hooks/useResource'
import isEqual from 'lodash/isEqual'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setEditAssetItem, setImportAssetList } from 'store/assetImport/actions'
import { selectAssetImportItemData, selectAssetImportListData } from 'store/assetImport/selectors'

export const useHandleResponse = (
  validateAssetRes: RequestState,
  setFormErrors: React.Dispatch<React.SetStateAction<any>>
) => {
  const dispatch = useDispatch()
  const settingItem = useSelector<any, Asset>(selectAssetImportItemData, isEqual)
  const settingList = useSelector<any, Asset[]>(selectAssetImportListData, isEqual)

  useEffect(() => {
    if (validateAssetRes.error) {
      setFormErrors(parseValidationError(validateAssetRes.error))
    } else if (validateAssetRes.data && settingList && settingItem) {
      const newSettingList = [...settingList]
      newSettingList[settingItem.index as number] = {
        ...parseDataForForm(validateAssetRes.data),
        index: settingItem.index,
      } as Asset
      dispatch(setImportAssetList(newSettingList))
      dispatch(setEditAssetItem(null))
      setFormErrors(undefined)
    }
  }, [validateAssetRes, settingList, settingItem])
}
