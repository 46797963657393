import { Unit } from 'common/models'

import { UnitListSelector } from './select.model'

export const selectUnitList = (state: any): Unit[] | null => {
  return state.getIn(['settings', 'unit', 'list', 'data'])
}

export const selectorUnitList = (state: any): UnitListSelector => {
  return {
    loading: state.getIn(['settings', 'unit', 'list', 'loading']),
    data: state.getIn(['settings', 'unit', 'list', 'data']) || [],
  }
}
