import { Button as ButtonAnt, Dropdown, Menu, Tooltip } from 'antd'
import { Permission } from 'common/constants'
import { getNs } from 'common/i18n-config'
import { FeatureFlagNames, openFeatureSlice } from 'common/open-features'
import React, { PropsWithChildren } from 'react'

import { StyledTooltipOverlay } from '../../shared'
import { Button, ButtonPerStyle, MenuIcon, MenuStyle, Wrapper } from './styles'

const { t, useNsReady } = getNs(['common'], 'actions')

export const TableDropdownMenu = ({
  data,
  permissions,
  onCopy,
  onDelete,
  onCorrection,
  isEnableCorrectButton,
  isHavePermissionToCorrect,
}: PropsWithChildren<Props>) => {
  useNsReady()

  const remoteFlags = openFeatureSlice.useSlice()

  const isAllowCorrection = remoteFlags[FeatureFlagNames.CorrectionFunction]

  const handleCopy = (item: any, event: any) => {
    event.stopPropagation()
    permissions?.isCreate && onCopy && onCopy(item)
  }

  const handleDelete = (item: any, event: any) => {
    event.stopPropagation()
    permissions?.isDelete && item.isDeletable && onDelete && onDelete(item)
  }

  const handleCorrection = (biid: string, event: any) => {
    event.stopPropagation()
    onCorrection && onCorrection(biid)
  }

  const Actions = (
    <Menu className={MenuStyle} onClick={event => event.domEvent.stopPropagation()}>
      <Menu.Item onClick={event => handleCopy(data, event.domEvent)} key="copy">
        {!permissions?.isCreate ? (
          withPermissionTooltip(
            <ButtonAnt disabled style={ButtonPerStyle} className="btn-no-permission">
              {t('copy')}
            </ButtonAnt>
          )
        ) : (
          <Button type="text" id={`copy-action-${data.key as string}`} className="text">
            {t('copy')}
          </Button>
        )}
      </Menu.Item>
      {isAllowCorrection ? (
        <Menu.Item key="correction" onClick={e => handleCorrection(data?.biid, e.domEvent)}>
          {!isHavePermissionToCorrect ? (
            withPermissionTooltip(
              <ButtonAnt type="link" className="btn-no-permission" disabled>
                {t('correction')}
              </ButtonAnt>
            )
          ) : (
            <Button type="link" className="text" disabled={!isEnableCorrectButton}>
              {t('correction')}
            </Button>
          )}
        </Menu.Item>
      ) : null}
      <Menu.Item
        onClick={event => handleDelete(data, event.domEvent)}
        key="delete"
        style={{ cursor: !data.isDeletable ? 'not-allowed' : 'pointer' }}
      >
        {!permissions?.isDelete ? (
          withPermissionTooltip(
            <ButtonAnt disabled style={ButtonPerStyle} className="btn-no-permission">
              {t('remove')}
            </ButtonAnt>
          )
        ) : (
          <Button
            danger
            type="text"
            id={`delete-action-${data.key as string}`}
            disabled={!data.isDeletable}
            className="text"
          >
            {t('remove')}
          </Button>
        )}
      </Menu.Item>
    </Menu>
  )

  return (
    <Wrapper>
      <Dropdown overlayClassName="popup-overlay-actions" placement="bottomRight" overlay={Actions} trigger={['click']}>
        <Button
          className="action-button"
          id={`popup-action-${data.key as string}`}
          type="text"
          onClick={event => event.stopPropagation()}
        >
          <MenuIcon />
        </Button>
      </Dropdown>
    </Wrapper>
  )
}

export const withPermissionTooltip = (WrappedComponent: React.ReactNode) => {
  return (
    <Tooltip
      overlayClassName={StyledTooltipOverlay}
      placement="top"
      title={t('no_permission')}
      className="ant-tooltip-custom"
      trigger="hover"
    >
      {WrappedComponent}
    </Tooltip>
  )
}

type Props = {
  data: any
  permissions?: Permission
  onCopy?: (data: any) => void
  onDelete?: (data: any) => void
  onCorrection?: (data: any) => void
  isEnableCorrectButton?: boolean
  isHavePermissionToCorrect?: boolean
}
