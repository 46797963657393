import { loadNs } from 'common/i18n-config'
import { array, number, object } from 'yup'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

function isBookValueAtChangeValidWithAcquisitionCost(value?: number, acquisitionCost?: number) {
  return value === undefined || acquisitionCost === undefined || value <= acquisitionCost
}

function isBookValueAtChangeValidWithServiceLifeAfterChange(
  value?: number,
  acquisitionCost?: number,
  serviceLife?: number,
  serviceLifeAfterChange?: number
) {
  return (
    value === undefined ||
    acquisitionCost === undefined ||
    serviceLife === undefined ||
    serviceLifeAfterChange === undefined ||
    value !== acquisitionCost ||
    serviceLife === serviceLifeAfterChange
  )
}

function isBookValueAtChangeValidWithMemorandumAmount(value?: number, memorandumValue?: number) {
  return value === undefined || memorandumValue === undefined || value >= memorandumValue
}

function isServiceLifeAfterChangeValid(serviceLife?: number, serviceLifeAfterChange?: number) {
  return serviceLife === undefined || serviceLifeAfterChange === undefined || serviceLife >= serviceLifeAfterChange
}

let generalErrors: {
  [key: string]: string
} = {}

export const depreciationSchema = array()
  .of(
    object().shape({
      memorandum_value: number(),
      service_life: number(),
      service_life_for_depreciation_calc: number().test(
        'service_life_after_change_over_service_life',
        t('service_life_after_change_over_service_life'),
        (value, context) => {
          const isValid = isServiceLifeAfterChangeValid(context.parent?.service_life, value)
          generalErrors[`${context.path}_test1`] = isValid ? '' : t('service_life_after_change_general_error')
          return isValid
        }
      ),
      acquisition_cost_for_straight_line: number()
        .test(
          'book_value_at_change_over_acquisition_cost',
          t('book_value_at_change_over_acquisition_cost'),
          (value, context) => {
            const parent = context.from?.find(parent => parent?.value.book_value_at_change_reference !== undefined)
            const isValid = isBookValueAtChangeValidWithAcquisitionCost(
              value,
              parent?.value.book_value_at_change_reference
            )
            generalErrors[`${context.path}_test1`] = isValid ? '' : t('book_value_at_change_general_error')

            return isValid
          }
        )
        .test(
          'booking_value_at_change_and_service_life_after_change',
          t('booking_value_at_change_and_service_life_after_change'),
          (value, context) => {
            const rootParent = context.from?.find(parent => parent?.value.book_value_at_change_reference !== undefined)
            const isValid = isBookValueAtChangeValidWithServiceLifeAfterChange(
              value,
              rootParent?.value.book_value_at_change_reference,
              context.parent?.service_life,
              context.parent?.service_life_for_depreciation_calc
            )
            generalErrors[`${context.path}_test2`] = isValid ? '' : t('book_value_at_change_general_error')
            return isValid
          }
        )
        .test(
          'book_value_at_change_under_memorandum_amount',
          t('book_value_at_change_under_memorandum_amount'),
          (value, context) => {
            const isValid = isBookValueAtChangeValidWithMemorandumAmount(value, context.parent?.memorandum_value)
            generalErrors[`${context.path}_test3`] = isValid ? '' : t('book_value_at_change_general_error')
            return isValid
          }
        ),
    })
  )
  .nullable()

function getLedgerErrorFromValidation(): string[] {
  const errors = new Set<string>()
  Object.keys(generalErrors).forEach(key => {
    if (generalErrors[key]) {
      errors.add(generalErrors[key])
    }
  })
  return Array.from(errors)
}

function getLedgerErrorFromErrors(errors: Record<string, any>): string[] {
  const errorSet = new Set<string>()

  Object.keys(errors).forEach(key => {
    key.includes('acquisition_cost_for_straight_line') && errorSet.add(t('book_value_at_change_general_error'))
    key.includes('service_life_for_depreciation_calc') && errorSet.add(t('service_life_after_change_general_error'))
  })

  return Array.from(errorSet)
}

export function getLedgerGeneralValidationErrors(errors?: any) {
  return errors && typeof errors === 'object' && Object.keys(errors).length
    ? getLedgerErrorFromErrors(errors)
    : getLedgerErrorFromValidation()
}

export function resetLedgerGeneralValidationErrors() {
  generalErrors = {}
}
