import { parseError, parseResponse } from 'common/helpers'
import { buildQueryFilterParams, RequestParam } from 'common/helpers/request-helper'
import { UpdateTagOrderPayload } from 'store/settings/tag/model'

import base from './base'

export const search = (params: RequestParam) => {
  return base
    .get('/v1/tags', {
      params: buildQueryFilterParams(params),
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const searchAuth = (params: RequestParam) => {
  return base
    .get('/v1/tags/get', {
      params: buildQueryFilterParams(params),
    })
    .then(res => parseResponse(res))
    .catch(err => parseError(err))
}

export const get = (id: string) => {
  return base.get(`/v1/tags/${id}`)
}

export const create = (data: any) => {
  return base.post('/v1/tags', data)
}

export const update = (id: string, data: any) => {
  return base.put(`/v1/tags/${id}`, data)
}

export const destroy = (id: string, dryRun: boolean) => {
  return base.delete(`/v1/tags/${id}`, { params: { dry_run: dryRun } })
}

export const updateOrder = (data: UpdateTagOrderPayload) => {
  return base.patch('/v1/tags/change_position', data)
}
