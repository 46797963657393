import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CONFIRM_TYPES } from 'aa_common/front-end'
import { AutoCompleteSelect, Button, Checkbox, Spin } from 'aa_common/front-end/antd'
import { Col, Drawer, Layout, Row } from 'antd'
import { FEATURES_ID } from 'common/constants'
import {
  convertTermListData,
  formatMonth,
  generateAccountPeriods,
  generateTermMonths,
  getAddNewNextSettings,
  getCountItems,
  getSettingByValidAt,
  getTermMonthById,
} from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES, TermMonth } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import useModal from 'hooks/useModalHook'
import useUserPermission from 'lib/hooks/useUserPermission'
import find from 'lodash/find'
import first from 'lodash/first'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteAssetCategoryJournalSettingsTerm,
  saveJournalItemSettings,
  updateJournalItemSettings,
} from 'store/accounting/journal/actions'
import { selectorSelectedAssetCategoryJournalSettingTerms } from 'store/accounting/journal/selectors'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { selectFullTermsList } from 'store/settings/accountingPeriod/selectors'

import JournalItemSettingForm from '../JournalItemSettingForm'
import ModalAutoJournalSettingList from '../ModalAutoJournalSettingList'
import { BreadcrumbArea, DefaultHalfModalClass, Wrapper } from './styles'

const { Content } = Layout

interface IAutoJournalSettingModalForm {
  assetCategory: any
  loading?: boolean
  handleDrawerClose: () => void
}

const t = loadNs([
  'common',
  'components/organisms/accounting/auto-journal-setting-tab',
  'components/organisms/accounting/general-journal-setting-tab',
])
const AutoJournalSettingHalfModal = ({ assetCategory, loading, handleDrawerClose }: IAutoJournalSettingModalForm) => {
  const { permissions } = useUserPermission(FEATURES_ID.SETTING_AUTO_JOURNAL)
  const confirmModal = useModal()
  const dispatch = useDispatch()

  const [isDetailMode, setIsDetailMode] = useState<boolean>(false)
  const [editItem, setEditItem] = useState<any>()
  const [nextSettings, setNextSettings] = useState<TermMonth | null>(null)
  const [formMode, setFormMode] = useState<FORM_MODES>(FORM_MODES.VIEW)
  const [validAtValue, setValidAtValue] = useState<any>()
  const [termYearPeriodValue, setTermYearPeriod] = useState<number>()
  const [termMonthValue, setTermMonth] = useState<number>()

  const accountPeriods = useSelector(selectFullTermsList, isEqual)
  const selectedAssetCategoryTermsSelector = useSelector(selectorSelectedAssetCategoryJournalSettingTerms, isEqual)
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)

  useEffect(() => {
    // Set default to form mode if asset category doesn't have any setting items
    // Otherwise, set default to view mode;
    if (selectedAssetCategoryTermsSelector.data?.length === 0) {
      setFormMode(FORM_MODES.NEW)
    } else {
      setFormMode(FORM_MODES.VIEW)
    }
  }, [selectedAssetCategoryTermsSelector.data])

  const settingTermList = useMemo(() => {
    const convertedData = convertTermListData(selectedAssetCategoryTermsSelector.data)
    const settingByValidAt = getSettingByValidAt(convertedData, validAtValue)

    return !isDetailMode ? settingByValidAt : convertedData
  }, [isDetailMode, selectedAssetCategoryTermsSelector.data, validAtValue])

  useEffect(() => {
    // Get next month settings for add new journal settings
    async function executeGetNextSettings() {
      const latestSettings = first(selectedAssetCategoryTermsSelector.data)

      const isFirstTime = !latestSettings && selectedAssetCategoryTermsSelector.data.length === 0
      let result: TermMonth | null = null

      if (isFirstTime && currentMonthlyClosing) {
        // If journal item doesn't have any settings (first time) - next settings value is current Monthly Closing
        result = currentMonthlyClosing.from_term_month
      } else if (latestSettings && accountPeriods.data?.length > 0) {
        result = getAddNewNextSettings(latestSettings, accountPeriods.data)
      }

      setNextSettings(result)
    }

    executeGetNextSettings()
  }, [selectedAssetCategoryTermsSelector.data, currentMonthlyClosing, accountPeriods.data])

  useEffect(() => {
    // Reset the filter value whenever user switch between asset categories;
    function loadByCurrentMonthlyClosing() {
      if (currentMonthlyClosing) {
        const period = currentMonthlyClosing.monthly_closing_setting.term.id
        const monthId = currentMonthlyClosing.from_term_month.id
        setTermYearPeriod(period)
        setTermMonth(monthId)
        setIsDetailMode(false)
        setFormMode(FORM_MODES.VIEW)
        setEditItem(null)
      }
    }

    loadByCurrentMonthlyClosing()
  }, [assetCategory, currentMonthlyClosing])

  useEffect(() => {
    if (termMonthValue) {
      const targetMonth = getTermMonthById(termMonthValue, accountPeriods.data)
      targetMonth && setValidAtValue(targetMonth.start_date)
    }
  }, [termMonthValue, accountPeriods.data])

  const isFormMode = useMemo(() => {
    return formMode === FORM_MODES.NEW || formMode === FORM_MODES.EDIT
  }, [formMode])

  const handleTermYearPeriodChanged = (termYearPeriod: any) => {
    setTermYearPeriod(termYearPeriod)
    const currentMonthlyClosingInThisYear = currentMonthlyClosing.monthly_closing_setting.term.period === termYearPeriod

    // Set default value when change term year
    // If current monthly closing in selected year, choose the current monthly closing month value
    // Otherwise choose the first month of selected term year period
    const termYear = find(accountPeriods.data, item => item.id === termYearPeriod)
    const firstMonthOfTermYear = first(termYear?.term_months)
    setTermMonth(currentMonthlyClosingInThisYear ? currentMonthlyClosing.from_term_month.id : firstMonthOfTermYear?.id)
  }

  const handleTermMonthChanged = (value: any) => setTermMonth(value)

  const countItems = useMemo(
    () => getCountItems(selectedAssetCategoryTermsSelector.data, currentMonthlyClosing),
    [selectedAssetCategoryTermsSelector.data, currentMonthlyClosing]
  )

  const renderBreadcrumbModalClose = useMemo(
    () => <BreadcrumbNavigation.CloseIconTemplate onCloseClick={handleDrawerClose} />,
    [handleDrawerClose]
  )

  const memoizedAccountPeriods = useMemo(() => generateAccountPeriods(accountPeriods.data), [accountPeriods.data])

  const memoizedTermMonths = useMemo(() => {
    const termYear = find(accountPeriods.data, item => item.id === termYearPeriodValue)
    return generateTermMonths(termYear?.term_months || [])
  }, [termYearPeriodValue, accountPeriods.data])

  const isLatestRecord = useMemo(() => {
    if (!isDetailMode) {
      const currentSettings = first(settingTermList)
      const isLatest = currentSettings?.from_term_month && !currentSettings?.to_term_month
      return isLatest
    }

    return false
  }, [isDetailMode, settingTermList])

  const latestRecord = useMemo(() => {
    const currentSettings = first(selectedAssetCategoryTermsSelector.data)
    return currentSettings
  }, [selectedAssetCategoryTermsSelector.data])

  const handleButtonEditClick = () => {
    const convertedData = convertTermListData(selectedAssetCategoryTermsSelector.data)
    const settingByValidAt = getSettingByValidAt(convertedData, validAtValue)

    if (settingByValidAt.length > 0) {
      setFormMode(FORM_MODES.EDIT)
      setEditItem(first(settingByValidAt))
    }
  }

  const handleDeleteSettingClick = useCallback((item: any) => {
    confirmModal
      .confirm({
        title: t('delete_journal_setting_item_title'),
        message: t('delete_journal_setting_item_message', {
          month: formatMonth(item?.from_term_month?.month),
          year: item?.from_term_month?.term?.year,
        }),
        type: CONFIRM_TYPES.DELETE,
      })
      .then(isOK => {
        isOK && dispatch(deleteAssetCategoryJournalSettingsTerm(item.id, item.asset_category_id))
      })
  }, []) // eslint-disable-line

  const handleModifySettingClick = useCallback((item: any) => {
    setFormMode(FORM_MODES.EDIT)
    setEditItem(item)
  }, [])

  const handleAddNewClick = useCallback(() => {
    setFormMode(FORM_MODES.NEW)
    setEditItem(null)
  }, [])

  const handleFormSubmit = useCallback(
    async (payload: any, id?: number) => {
      const isModify = formMode === FORM_MODES.EDIT && isDetailMode
      const isCreate = formMode === FORM_MODES.NEW
      const isEdit = formMode === FORM_MODES.EDIT && !isDetailMode
      const isDifferentFromTermMonth = payload?.from_term_month_id !== get(latestRecord, 'from_term_month.id')

      try {
        if (payload) {
          const isUpdate = isModify || (isEdit && !isDifferentFromTermMonth)
          if (id && isUpdate) {
            await dispatch(updateJournalItemSettings(id, payload))
          } else if (isCreate || (isEdit && isDifferentFromTermMonth)) {
            await dispatch(saveJournalItemSettings(payload))
          }
          setEditItem(null)
          setFormMode(FORM_MODES.VIEW)
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [dispatch, formMode, isDetailMode, latestRecord]
  )

  const handleFormCancelClick = useCallback(() => {
    setEditItem(null)
    setFormMode(FORM_MODES.VIEW)
    if (settingTermList.length === 0) {
      handleDrawerClose()
    }
  }, [settingTermList, handleDrawerClose])

  return (
    <Drawer width={980} className={DefaultHalfModalClass} visible={!!assetCategory} closable={false} mask={false}>
      <Wrapper>
        <Spin loading={loading}>
          <div className="help">
            <FontAwesomeIcon style={{ color: '#7C8291', fontSize: 20 }} icon={faQuestionCircle} />
          </div>
          <BreadcrumbArea>
            <Row>
              <Col flex="1 1" style={{ minWidth: 0 }}>
                <BreadcrumbNavigation
                  customBackElement={renderBreadcrumbModalClose}
                  sectionName={assetCategory?.name}
                />
              </Col>
              <Col flex="0 1 auto" />
            </Row>
          </BreadcrumbArea>
          <Layout>
            {assetCategory && (
              <Content className="half-modal-content" id="auto-journal-setting-modal-form-wrapper">
                <div className="top-header-template">
                  <div className="left">
                    <AutoCompleteSelect
                      name="term_year_period"
                      items={memoizedAccountPeriods}
                      textField="label"
                      valueField="value"
                      disabled={isDetailMode || isFormMode}
                      value={termYearPeriodValue}
                      onChange={handleTermYearPeriodChanged}
                      allowClear={false}
                      style={{ marginRight: 5, width: 120 }}
                    />
                    <AutoCompleteSelect
                      name="term_month"
                      items={memoizedTermMonths}
                      disabled={isDetailMode || isFormMode}
                      textField="label"
                      valueField="value"
                      value={termMonthValue}
                      onChange={handleTermMonthChanged}
                      allowClear={false}
                      style={{ width: 120, marginRight: 5 }}
                    />
                    <span className="display-status-text">{t('list_display_status')}</span>
                  </div>
                  <div className="middle">
                    <Checkbox
                      disabled={isFormMode}
                      checked={isDetailMode}
                      name="display-all"
                      onChange={event => setIsDetailMode(event.checked)}
                    >
                      <span>
                        {t('display_all_periods')}
                        <span style={{ color: isDetailMode || isFormMode ? '#D4D8DD' : '#333333' }}>
                          {t('future_past_count', {
                            past_count: countItems.pastItems,
                            future_count: countItems.futureItems,
                          })}
                        </span>
                      </span>
                    </Checkbox>
                  </div>
                  <div className="right">
                    <Button
                      showTooltipMessage={!permissions.isCRU ? t('actions.no_permission') : undefined}
                      disabled={!isLatestRecord || isDetailMode || !permissions.isCRU || isFormMode}
                      color="grey"
                      tooltipSettings={{
                        placement: 'topRight',
                      }}
                      onClick={handleButtonEditClick}
                      data-testid="edit-button"
                    >
                      {t('actions.edit')}
                    </Button>
                  </div>
                </div>
                <ModalAutoJournalSettingList
                  visible={formMode === FORM_MODES.VIEW}
                  items={settingTermList}
                  isFormMode={isFormMode}
                  isDetailMode={isDetailMode}
                  onAddNewClick={handleAddNewClick}
                  onDeleteClick={handleDeleteSettingClick}
                  onModifyClick={handleModifySettingClick}
                />
                <JournalItemSettingForm
                  nextSettings={nextSettings}
                  selectedAssetCategory={assetCategory}
                  isDetailMode={isDetailMode}
                  editItem={editItem}
                  formMode={formMode}
                  onCancelClick={handleFormCancelClick}
                  onFormSubmit={handleFormSubmit}
                />
              </Content>
            )}
          </Layout>
        </Spin>
      </Wrapper>
    </Drawer>
  )
}

export default AutoJournalSettingHalfModal
