import { getNs } from 'common/i18n-config'
import { AssetLedger } from 'common/models'
import uniqueId from 'lodash/uniqueId'
import React from 'react'

import { AmortizationTable } from './styles'
import { DepreciationComparisonTable } from './tables'
import { getDepreciationData } from './tables/helper'
import { useGetDepreciation } from './tables/useGetDepreciation'

interface IAmortizationInfoTable {
  ledger: AssetLedger
  depreciationRate: number | null
  isShowComparisonTable: boolean
  biid?: string
  acquisitionCost: number | null
  isOpenFeatureFlagChangeDmethod?: boolean
}

const { t, useNsReady } = getNs(['components/organisms/assets/asset-detail', 'common'])

const AmortizationInfoTable = ({
  ledger,
  depreciationRate,
  isShowComparisonTable,
  biid,
  acquisitionCost,
  isOpenFeatureFlagChangeDmethod,
}: IAmortizationInfoTable) => {
  useNsReady()
  const { renderDepreciationRate } = useGetDepreciation(ledger)

  const depreciationRenderData = getDepreciationData(
    t,
    ledger,
    depreciationRate,
    renderDepreciationRate,
    ledger.depreciation_method?.code,
    acquisitionCost,
    isOpenFeatureFlagChangeDmethod
  )

  const renderTable = () => {
    return (
      <AmortizationTable>
        <tbody>
          {depreciationRenderData?.map(data => (
            <tr key={`row-${uniqueId()}`}>
              {data.map(item => (
                <React.Fragment key={`col-${uniqueId()}`}>
                  <th>{item.title}</th>
                  <td colSpan={data.length === 1 ? 3 : 0}>
                    <span>{item.value}</span>
                  </td>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </AmortizationTable>
    )
  }

  if (isShowComparisonTable) {
    return <DepreciationComparisonTable biid={biid} />
  }

  return renderTable()
}

export default AmortizationInfoTable
