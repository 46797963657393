export const ASSET_CODE_FIELD = 'asset_code'

export const codeField = {
  text: 'code',
  maxLength: 15,
}

export const branchCodeField = {
  text: 'branch_code',
  maxLength: 3,
}

export const nameField = {
  text: 'name',
  maxLength: 50,
}

export const LEDGERS_FIELD_NAME = 'fixed_asset_ledgers'

export const DEPRECIATION_FIELDS = {
  LEDGER_SETTING_ID: 'ledger_setting_id',
  SERVICE_LIFE: 'service_life',
  SERVICE_LIFE_AFTER_CHANGE: 'service_life_for_depreciation_calc',
  BOOK_VALUE_AT_CHANGE: 'acquisition_cost_for_straight_line',
  USAGE_PERIOD_MONTHS: 'usage_period_months',
  RESIDUAL_AMOUNT: 'residual_amount',
  DEPRECIATION_METHOD_CODE: 'depreciation_method_code',
  DEPRECIATION_RATE: 'depreciation_rate',
  GUARANTEE_RATE: 'guarantee_rate',
  REVISED_DEPRECIATION_RATE: 'revised_depreciation_rate',
  ACCOUNTING_BEGINNING_BOOK_INITIAL_VALUE: 'accounting_beginning_book_initial_value',
  TRANSITION_BEGINNING_BOOK_VALUE: 'transition_beginning_book_value',
  TRANSITION_BOOK_VALUE: 'transition_book_value',
  REVISED_ACQUISITION_AMOUNT: 'revised_acquisition_amount',
  DEPRECIATED_MONTHS: 'depreciated_months',
  TRANSITION_AT: 'transition_at',
  ACCUMULATED_DEPRECIATION_AMOUNT: 'accumulated_depreciation_amount',
  ACCUMULATED_IMPAIRED_AMOUNT: 'accumulated_impaired_amount',
  BOOK_VALUE: 'book_value',
  MEMORANDUM_VALUE: 'memorandum_value',
  RETIREMENT_DEPRECIATION_ENDED_METHOD: 'retirement_depreciation_ended_method',
  GAIN: 'gain',
  LOSS: 'loss',
  IS_COPIED: 'is_copied',
  ACCUMULATED_OVER_DEPRECIATION_AMT: 'accumulated_over_depreciation_amt',
  IS_MEMORANDUM_VALUE_ENTERED: 'is_memorandum_value_entered',
}

export const FORM_FIELDS = {
  BOOK_VALUE_AT_CHANGE_REFERENCE: 'book_value_at_change_reference',
}

export const ERROR_FROM_API = '___ERROR_FROM_API___'
