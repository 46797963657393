import { Button } from 'aa_common/front-end/antd'
import { FEATURES_ID, Permission } from 'common/constants'
import { getNs } from 'common/i18n-config'
import { FeatureFlagNames, openFeatureSlice } from 'common/open-features'
import { DropdownMenuButton } from 'components/molecules'
import { DropdownMenuSettings } from 'components/molecules/DropdownMenuButton'
import useUserPermission from 'lib/hooks/useUserPermission'
import { Moment } from 'moment'
import { assetListPageStore, BulkChangeEvent } from 'pages/assets/AssetListPage/store'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'
import { useDebounce } from 'use-debounce/lib'

import ValidAtDatepicker from './components/date-picker-validation'
import { useGetSelectedItemOnPage } from './hooks'
import { LeftWrapper, RightWrapper, TopTemplateWrapper } from './styles'

const { t, useNsReady } = getNs([
  'common',
  'pages/assets',
  'components/organisms/assets/asset-list',
  'components/organisms/assets/asset-usage-started-form',
  'components/organisms/assets/asset-movement-form',
  'components/organisms/assets/asset-retirement-form',
  'components/organisms/assets/asset-type-modify-form',
])

const AssetListTopTemplate = ({
  valid_at,
  totalRecords,
  fixedAssetPermissions,
  exportPermissions,
  onExportClick,
  onValidAtChanged,
}: AssetListTopTemplateProps) => {
  useNsReady()

  const dispatch = useDispatch()
  const hasRowSelected = useGetSelectedItemOnPage().selectedIds?.length > 0
  const { permissions: startUsagePermissions } = useUserPermission(FEATURES_ID.START_USAGE)
  const { permissions: movementPermissions } = useUserPermission(FEATURES_ID.MOVEMENT)
  const { permissions: retirePermissions } = useUserPermission(FEATURES_ID.RETIRE)
  const { permissions: typeModifyPermissions } = useUserPermission(FEATURES_ID.TYPE_MODIFICATION)

  const remoteFlags = openFeatureSlice.useSlice()

  const disabledBulkChangeButton =
    !startUsagePermissions.isCRU &&
    !startUsagePermissions.isCRUD &&
    !movementPermissions.isCRU &&
    !movementPermissions.isCRUD &&
    !retirePermissions.isCRU &&
    !retirePermissions.isCRUD &&
    !typeModifyPermissions.isCRU &&
    !typeModifyPermissions.isCRUD

  const handleAdd = useCallback(() => dispatch(changeLocation('/assets/new')), [dispatch])

  const handleImport = useCallback(() => dispatch(changeLocation('/assets/import')), [dispatch])

  const [debounceValidAt] = useDebounce(valid_at, 200)

  const noPermissionTooltip = { placement: 'top', title: t('actions.no_permission') }
  const noStartUsagePermissions = !startUsagePermissions.isCRU && !startUsagePermissions.isCRUD
  const noMovementPermissions = !movementPermissions.isCRU && !movementPermissions.isCRUD
  const noRetirePermissions = !retirePermissions.isCRU && !retirePermissions.isCRUD
  const noTypeModifyPermissions = !typeModifyPermissions.isCRU && !typeModifyPermissions.isCRUD
  const menuSettings = [
    ...(remoteFlags[FeatureFlagNames.CollectionViewBulkChangeStartUsage]
      ? [
          {
            label: t('usage_started'),
            disabled: noStartUsagePermissions,
            tooltip: noStartUsagePermissions ? noPermissionTooltip : undefined,
            onClick: () => assetListPageStore.setBulkChangeEvent(BulkChangeEvent.START_USAGE),
          },
        ]
      : []),
    ...(remoteFlags[FeatureFlagNames.CollectionViewBulkChangeMovement]
      ? [
          {
            label: t('movement'),
            disabled: noMovementPermissions,
            tooltip: noMovementPermissions ? noPermissionTooltip : undefined,
            onClick: () => assetListPageStore.setBulkChangeEvent(BulkChangeEvent.MOVEMENT),
          },
        ]
      : []),
    ...(remoteFlags[FeatureFlagNames.CollectionViewBulkChangeRetirement]
      ? [
          {
            label: t('retirement'),
            disabled: noRetirePermissions,
            tooltip: noRetirePermissions ? noPermissionTooltip : undefined,
            onClick: () => assetListPageStore.setBulkChangeEvent(BulkChangeEvent.RETIREMENT),
          },
        ]
      : []),
    ...(remoteFlags[FeatureFlagNames.CollectionViewBulkChangeChangeAssetType]
      ? [
          {
            label: t('modify_type'),
            disabled: noTypeModifyPermissions,
            tooltip: noTypeModifyPermissions ? noPermissionTooltip : undefined,
            onClick: () => assetListPageStore.setBulkChangeEvent(BulkChangeEvent.CHANGE_ASSET_TYPE),
          },
        ]
      : []),
  ] as DropdownMenuSettings[]

  const toolTipBulkChange = useMemo(() => {
    if (disabledBulkChangeButton) {
      return t('actions.no_permission')
    }
    return ''
  }, [disabledBulkChangeButton])
  return (
    <TopTemplateWrapper>
      <LeftWrapper>
        <div className="datepicker-section">
          <div className="datepicker">
            <ValidAtDatepicker valid_at={debounceValidAt} onDateChanged={onValidAtChanged} />
          </div>
          <span className="display-status">{t('list_display_status')}</span>
        </div>
      </LeftWrapper>
      <RightWrapper>
        <div className="actions">
          <Button
            size="short"
            color="grey"
            onClick={onExportClick}
            className="export"
            disabled={!totalRecords || !exportPermissions.isRead}
            showTooltipMessage={!exportPermissions.isRead ? t('actions.no_permission') : undefined}
            data-testid="export-button"
          >
            {t('actions.export')}
          </Button>
          <Button
            size="short"
            color="grey"
            onClick={handleImport}
            className="import"
            disabled={!fixedAssetPermissions.isCreate}
            showTooltipMessage={!fixedAssetPermissions.isCreate ? t('actions.no_permission') : undefined}
          >
            {t('actions.import')}
          </Button>
          {!!remoteFlags['collection-view.bulk-change'] && (
            <DropdownMenuButton
              trigger={['hover']}
              menuSettings={menuSettings}
              disabled={disabledBulkChangeButton || !hasRowSelected}
              tooltipMessage={toolTipBulkChange}
            >
              {t('actions.bulk_change')}
            </DropdownMenuButton>
          )}
          <Button
            onClick={handleAdd}
            disabled={!fixedAssetPermissions.isCreate}
            tooltipSettings={{ placement: 'topRight' }}
            showTooltipMessage={!fixedAssetPermissions.isCreate ? t('actions.no_permission') : undefined}
          >
            {t('actions.add_new')}
          </Button>
        </div>
      </RightWrapper>
    </TopTemplateWrapper>
  )
}

type AssetListTopTemplateProps = {
  valid_at?: string | Moment
  totalRecords: number
  fixedAssetPermissions: Permission
  exportPermissions: Permission
  onExportClick: () => void
  onValidAtChanged: (value?: string) => void
}

export default AssetListTopTemplate
