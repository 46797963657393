import * as assetApi from 'api/app/asset'
import { getAssetPayload } from 'common/helpers/asset-helper'
import { Asset, FORM_MODES } from 'common/models'
import { AssetForm } from 'components/organisms'
import useResource from 'lib/hooks/useResource'
import { isEqual } from 'lodash'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setEditAssetItem } from 'store/assetImport/actions'
import { selectAssetImportItemData } from 'store/assetImport/selectors'

import { useHandleResponse } from './hooks'
import { StyledDrawer } from './styles'

const SettingFormDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState<boolean>(false)
  const settingItem = useSelector<any, Asset>(selectAssetImportItemData, isEqual)
  const [validateAssetRes, validateAsset] = useResource(assetApi.create)
  const [formErrors, setFormErrors] = useState()

  const handleClose = () => {
    dispatch(setEditAssetItem(null))
    setFormErrors(undefined)
  }

  const handleSubmit = (values: any) => {
    validateAsset({ ...getAssetPayload(values), dry_run: true })
  }

  useLayoutEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'auto'
  }, [visible])

  useEffect(() => {
    setVisible(!!settingItem)
  }, [settingItem])

  useHandleResponse(validateAssetRes, setFormErrors)

  return (
    <StyledDrawer
      title=""
      placement="right"
      closable
      onClose={handleClose}
      visible={visible}
      width={1060}
      destroyOnClose
    >
      <div id="asset-edit-import-form">
        {settingItem && (
          <AssetForm
            initialAssetData={settingItem}
            errors={formErrors}
            formMode={FORM_MODES.IMPORT}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    </StyledDrawer>
  )
}

export default SettingFormDrawer
