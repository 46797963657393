import { Tag } from 'common/models'

import { TagListSelector } from './model'

export const selectTagList = (state: any): Tag[] | null => {
  return state.getIn(['settings', 'tag', 'list', 'data'])
}

export const selectorTagList = (state: any): TagListSelector => {
  return {
    loading: state.getIn(['settings', 'tag', 'list', 'loading']),
    data: state.getIn(['settings', 'tag', 'list', 'data']) || [],
  }
}
