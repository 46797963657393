import { loadNs } from 'common/i18n-config'
import { BreadcrumbNavigation } from 'components/molecules'
import TagForm from 'components/organisms/settings/TagForm'
import { MainTemplate } from 'components/templates'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { cleanupTag, fetchTag, updateTag } from 'store/settings/tag/actions'

const t = loadNs(['pages/settings/tags'])

interface TagEditPageProps {
  tag: any
  errors: any
  formLoading: boolean
  fetchTag: (id: number) => void
  updateTag: (id: number, payload: any) => void
  cleanupTag: () => void
}

const TagEditPage = ({ tag, errors, formLoading = false, fetchTag, updateTag, cleanupTag }: TagEditPageProps) => {
  const { id }: any = useParams()

  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={t('edit')} />

  const handleSubmit = (values: any): void => {
    updateTag && updateTag(id, values)
  }

  useEffect(() => {
    fetchTag(id)
  }, [fetchTag, id])

  useEffect(() => {
    return function cleanup(): void {
      cleanupTag()
    }
  }, [cleanupTag])

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <TagForm values={tag} errors={errors} onSubmit={handleSubmit} type="edit" loading={formLoading} />
    </MainTemplate>
  )
}

const mapStateToProps = (state: Readonly<any>) => ({
  tag: state.getIn(['settings', 'tag', 'item', 'data']),
  errors: state.getIn(['settings', 'tag', 'item', 'errors']),
  formLoading: state.getIn(['settings', 'tag', 'item', 'loading']),
})

const mapDispatchToProps = {
  fetchTag,
  updateTag,
  cleanupTag,
}

export default connect(mapStateToProps, mapDispatchToProps)(TagEditPage)
