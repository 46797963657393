import { Client } from '@openfeature/web-sdk'
import { initSlice } from 'aa_common/front-end/zustand'

export enum FeatureFlagNames {
  CollectionViewBulkChange = 'collection-view.bulk-change',
  CollectionViewBulkChangeStartUsage = 'collection-view.bulk-change.start_usage',
  CollectionViewBulkChangeMovement = 'collection-view.bulk-change.movement',
  CollectionViewBulkChangeRetirement = 'collection-view.bulk-change.retire',
  CollectionViewBulkChangeChangeAssetType = 'collection-view.bulk-change.change-asset-type',
  CollectionViewBulkChangeChangeSaleType = 'collection-view.bulk-change.sale',
  CollectionViewBulkChangeChangeImpairmentType = 'collection-view.bulk-change.impairment',
  CollectionViewBulkChangeExport = 'collection-view.bulk-change.export',
  CollectionViewBulkChangeImport = 'collection-view.bulk-change.import',
  CorrectionFunction = 'correction_function',
  UseAACommonComponents = 'use-aa-common-components',
  ChangeDmethodD10D11 = 'change_dmethod_d10_d11',
  MultiExportTaxDeclaration = 'multi-export-tax-declaration',
}

export type FeatureNameType =
  | FeatureFlagNames.CollectionViewBulkChange
  | FeatureFlagNames.CollectionViewBulkChangeStartUsage
  | FeatureFlagNames.CollectionViewBulkChangeMovement
  | FeatureFlagNames.CollectionViewBulkChangeRetirement
  | FeatureFlagNames.CollectionViewBulkChangeChangeAssetType
  | FeatureFlagNames.CollectionViewBulkChangeChangeSaleType
  | FeatureFlagNames.CollectionViewBulkChangeChangeImpairmentType
  | FeatureFlagNames.CollectionViewBulkChangeExport
  | FeatureFlagNames.CollectionViewBulkChangeImport
  | FeatureFlagNames.CorrectionFunction
  | FeatureFlagNames.UseAACommonComponents
  | FeatureFlagNames.ChangeDmethodD10D11
  | FeatureFlagNames.MultiExportTaxDeclaration

const initState: Partial<Record<FeatureNameType, boolean>> = {}

export const openFeatureSlice = initSlice('open-features', initState)

const featureList: FeatureType[] = [
  {
    featureName: FeatureFlagNames.CollectionViewBulkChange,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.CollectionViewBulkChangeStartUsage,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.CollectionViewBulkChangeMovement,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.CollectionViewBulkChangeRetirement,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.CollectionViewBulkChangeChangeAssetType,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.CollectionViewBulkChangeChangeSaleType,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.CollectionViewBulkChangeChangeImpairmentType,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.CollectionViewBulkChangeExport,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.CollectionViewBulkChangeImport,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.CorrectionFunction,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.UseAACommonComponents,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.ChangeDmethodD10D11,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.MultiExportTaxDeclaration,
    defaultValue: false,
  },
]

export const isOpenFeatureDevMode = () => {
  const { REACT_APP_ENV, REACT_APP_RELAY_URL } = process.env
  return REACT_APP_ENV === 'local' && !REACT_APP_RELAY_URL
}
export const checkAllFeatures = async (client?: Client) => {
  let i = 0
  const state: Record<string | number, boolean> = {}
  while (i < featureList.length) {
    const current = featureList[i++]
    // if client undefined -> connect error -> set default state
    const featureFlagValue = client?.getObjectValue?.('features-flag', [], {})
    state[current.featureName] =
      (Array.isArray(featureFlagValue) && featureFlagValue?.includes(current.featureName)) || current.defaultValue
    if (isOpenFeatureDevMode()) {
      state[current.featureName] = true
    }
  }
  openFeatureSlice.setState(state, 'set-open-features')
}

type FeatureType = {
  featureName: FeatureNameType
  defaultValue: any
}
