import { updateObjectValues } from 'common/helpers/object-helper'
import Form from 'components/molecules/Form'
import React, { useMemo, useState } from 'react'

import RenderTagForm from './Form'
import { Wrapper } from './styles'

const initialValues = {
  name: '',
}

type TagFormProps = {
  errors?: any
  values?: any
  type: 'new' | 'edit'
  loading?: boolean
  onSubmit: (values: any) => void
}

const TagForm = ({ values, type = 'new', loading = false, errors, onSubmit }: TagFormProps) => {
  const [formValues, setFormValues] = useState(initialValues)

  useMemo(() => {
    if (values && type === 'edit') {
      const objectValues = updateObjectValues(initialValues, values)
      setFormValues({ ...objectValues })
    }
  }, [values, type])

  return (
    <Wrapper>
      <Form value={formValues} errors={errors} onFormSubmit={onSubmit} loading={loading}>
        {form => <RenderTagForm form={form} />}
      </Form>
    </Wrapper>
  )
}

export default TagForm
